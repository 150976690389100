import React, {useEffect, useState} from "react";
import Wallet from "../css/inGamePopup.module.css";
import MessagePopup, {CheckPlatform, WarningMessagePopup} from "./messagePopup";
import axios from "axios";
import baseUrl from "../BaseUrl";
import numberToKorean from "../popups/popupComman";
import $ from "jquery";

const InGameWallet = () => {
    const [page, setPage] = useState('menu_exchange');
    const [unitDisplayGO, setUnitDisplayGO] = useState('');
    const [moneyData, setMoneyData] = useState();
    const [messagePopup, setMessagePopup] = useState(false);
    const [status, setStatus] = useState(true);
    const [platformMessage, setPlatformMessage] = useState('');
    const [showCloseBtn, setShowCloseBtn] = useState(false);
    const [avatar, setAvatar] = useState([]);
    const [profileAvatar, setProfileAvatar] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [actionAvatar, setActionAvatar] = useState('');
    const [avatarCount, setAvatarCount] = useState(0);
    const [totalMoneyTxt, setTotalMoneyTxt] = useState('');
    const [warningPopup, setWarningPopup] = useState('');

    const itemsPerPage = 6;


    const params = new URLSearchParams(window.location.search);
    const gameName = params.get('gameName');
    const token = params.get('token');


    const priceList = (number) => {
        return parseInt(number.toString().replace(/0/g, ''), 10) + "억" + unitDisplayGO;
    };

    const changeMenu = (menu) => {
        featchData(menu);
        setPage(menu);
    };

    const menuAction = (avatar_id, reward, avatar_count) => {
        setActionAvatar(avatar_id);
        if(page === "menu_exchange") {
            var message = " 구매한 상품을 사용하시면 환불이 불가합니다.";
            setWarningPopup(message);

            var amount = reward * avatar_count;
            message = priceList(amount) + " 이 보유머니로 전환되었습니다.";
            setTotalMoneyTxt(message);
            setAvatarCount(avatar_count);
        } else {
            setStatus(true);
            setMessagePopup('아바타 변경이 완료되었습니다.');
        }
    };

    const doAction = async (popupStatus) => {
        if (!status) {
            setStatus(true);
            return setMessagePopup(popupStatus);
        }

        setMessagePopup(popupStatus);
        setWarningPopup(popupStatus);

        if (totalMoneyTxt) {
            setStatus(true);
            setMessagePopup(totalMoneyTxt);
            setTotalMoneyTxt('');
            return;
        }

        if(!actionAvatar) {
            return;
        }

        if(page === "menu_exchange"){
            const data = {
                category: "do_exchange",
                d_avatarid: actionAvatar,
                d_avatar_count: avatarCount
            };

            try {
                const response = await axios.post(baseUrl + 'popups/wallet/', data,{
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                });

                if (response.status === 200) {
                    setCurrentIndex(0);
                    setAvatar(response.data.avatars);
                    setMoneyData(response.data.total_money);
                    setNumberOfPages(Math.ceil(response.data.avatars.length / 6));

                    if(response.data.avatars.length === 0){
                        setStatus(false);
                        setMessagePopup("미전환 아바타가 없습니다.");
                    }
                } else if(response.status === 204) {
                    setStatus(false);
                    setMessagePopup("게임 진행 중에는 금고 입•출금이 제한 됩니다.");
                }

            } catch (e) {

            }
        } else {
            const data = {
                category: "do_avatar_change",
                d_avatarid: actionAvatar
            };

            try {
                const response = await axios.post(baseUrl + 'popups/wallet/', data,{
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                });

                if (response.status === 200) {
                    sessionStorage.clear();
                    setAvatar(response.data.avatars);
                    setProfileAvatar(response.data.profile_avatar);
                    setNumberOfPages(Math.ceil(response.data.avatars.length / 6));

                } else if(response.status === 204) {
                    setStatus(false);
                    setMessagePopup("게임 진행 중에는 금고 입•출금이 제한 됩니다.");
                }

            } catch (e) {

            }
        }
    };

    const featchData = async (category) => {
        try {
            const response = await axios.get(baseUrl + 'popups/wallet/?category=' + category, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200) {
                if (category === 'menu_exchange') {
                    setCurrentIndex(0);
                    setAvatar(response.data.avatars);
                    setMoneyData(response.data.total_money);

                    if(response.data.avatars.length === 0){
                        setStatus(false);
                        setMessagePopup("미전환 아바타가 없습니다.");
                    }

                } else {
                    setCurrentIndex(0);
                    setAvatar(response.data.avatars);
                    setProfileAvatar(response.data.profile_avatar);
                }

                setNumberOfPages(Math.ceil(response.data.avatars.length / 6));
            }
        } catch (error) {
            alert("Invalid token");
        }
    };


    const nextPage = () => {
        if (currentIndex + itemsPerPage < avatar.length) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }

    };

    const prevPage = () => {
        if (currentIndex - itemsPerPage >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const dotPage = (index) => {
        setCurrentIndex(index * itemsPerPage);
    };


    useEffect(() => {
        $('body').css({
            'transform': 'unset',
            'min-width': 'unset',
            'zoom': 'unset',
        });

        if (!gameName) {
            return alert("gameName is missing in url parameter");
        } else if (!token){
            return alert("token is missing in url parameter");
        }

        featchData('menu_exchange');

        if (gameName === "sm_dualgo") {
            setUnitDisplayGO("냥");
        } else {
            setUnitDisplayGO("골드");
        }

        var checkPlatform = CheckPlatform();
        setPlatformMessage(checkPlatform[0]);
        setShowCloseBtn(checkPlatform[1]);
    }, []);


    return (
        <>
            {messagePopup && (
                    <MessagePopup message={messagePopup} status={status} closeMessagePopup={doAction}/>)}

            {warningPopup && (
                    <WarningMessagePopup message={warningPopup} closeMessagePopup={doAction} warningPopup={setWarningPopup}/>)}


            { showCloseBtn && (<div className={Wallet.in_game_close_btn} onClick={() => window.self.close()}/>)}

            <div className={Wallet.in_game_popup_background}>

                <div>
                    <div className={Wallet.cash_box_currency_switch}>
                        <div
                            onClick={() => changeMenu('menu_exchange')}
                            className={`${Wallet.currency_option} ${ page === 'menu_exchange' ?  Wallet.currency_option_active: Wallet.currency_option_not_active }`}
                        >
                            <p style={{
                                color: page === 'menu_exchange' ? '#fff' : '#e4831b',
                                fontSize: '1.6vw',
                                fontWeight: 700
                            }}>머니전환</p>
                        </div>
                        <div
                            onClick={() => changeMenu('menu_select')}
                            className={`${Wallet.currency_option} ${ page === 'menu_select' ?  Wallet.currency_option_active: Wallet.currency_option_not_active }`}
                        >
                            <p style={{
                                color: page === 'menu_select' ? '#fff' : '#e4831b',
                                fontSize: '1.6vw',
                                fontWeight: 700
                            }}>아바타 변경</p>
                        </div>
                    </div>

                    <div className={Wallet.wallet_currency_box}>
                        <div className={Wallet.wallet_currency_box_2}>
                            <span style={{width: '30%'}}>보유머니</span>
                            {numberToKorean(moneyData, unitDisplayGO)}
                        </div>
                    </div>

                    <div className={Wallet.wallet_content}>
                        <div onClick={prevPage} style={{marginRight: '3.1vw', width: '4vw'}}>
                            <div className={Wallet.wallet_left_arrow}
                                 style={{display: currentIndex === 0 ? 'none' : 'block'}}/>
                        </div>

                        <div className={Wallet.wallet_avatars}>
                            {avatar.slice(currentIndex, currentIndex + itemsPerPage).map((item, index) => {
                                return (
                                    <div key={index} className={Wallet.wallet_avatars_card}>
                                        <div className={Wallet.wallet_avatars_card_div}>
                                            <img className={Wallet.wallet_avatars_card_img}
                                                 src={require('../images/shop_img/' + item.d_avatarid + '.gif')}/>

                                            <div className={Wallet.wallet_card_content}>
                                                <div className={Wallet.wallet_avatar_bag_text}>
                                                    {item.d_avatar_name}
                                                </div>

                                                <div className={Wallet.wallet_avatar_money_txt}>
                                                    {page === "menu_exchange" ? priceList(item.d_reward * item.d_avatar_count): ''}
                                                </div>

                                                <div className={Wallet.wallet_avatar_select_btn_div}>
                                                    {page === "menu_select" && item.d_avatarid === profileAvatar ?

                                                        <div className={Wallet.wallet_avatar_profile_selected}>
                                                            <span className={Wallet.wallet_avatar_select_button_txt} style={{color: '#E4831B'}}>
                                                               선택됨
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className={Wallet.wallet_avatar_select_button} onClick={() => menuAction(item.d_avatarid, item.d_reward, item.d_avatar_count)}>
                                                            <span className={Wallet.wallet_avatar_select_button_txt}>
                                                               {page === "menu_exchange" ? '사용' : '선택'}
                                                            </span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>

                                            <div className={Wallet.wallet_avatar_count}>
                                                {page === "menu_exchange" && (
                                                    <div className={Wallet.wallet_avatar_count_box}>
                                                            <span
                                                                className={Wallet.wallet_avatar_count_box_txt}>{item.d_avatar_count}</span>
                                                    </div>)}
                                            </div>

                                        </div>
                                    </div>
                                );
                            })}


                            {(currentIndex + itemsPerPage) > avatar.length && Array.from({length: (currentIndex + itemsPerPage) - avatar.length}, (_, index) => (
                                <div key={index} className={Wallet.wallet_avatars_card}>
                                    <div className={Wallet.wallet_avatars_card_div}>

                                    </div>
                                </div>
                            ))}

                        </div>

                        <div onClick={nextPage} style={{width: '4vw'}}>
                            <div className={Wallet.wallet_right_arrow}
                                 style={{display: currentIndex + itemsPerPage >= avatar.length ? 'none' : 'block'}}/>
                        </div>

                    </div>

                    <div className={Wallet.wallet_avatars_pagination}>
                        {Array.from({length: numberOfPages}, (_, index) => (
                            <div
                                className={currentIndex === itemsPerPage * index ? Wallet.wallet_pagination_active_dot : Wallet.wallet_pagination_inactive_dot}
                                onClick={event => dotPage(index)}
                            >
                            </div>
                        ))}
                    </div>


                </div>


                <div className="day_limit_bottom_txt" style={{position: 'relative', marginTop: "4vh"}}>
                    {platformMessage}
                </div>
            </div>

        </>
    );
};

export default InGameWallet;