import React, {useEffect, useState} from 'react';
import Header from "./home/header";
import Home from "./home/home";
import Footer from "./home/footer";
import MobileIndex from "./mobile/mIndex";
import $ from 'jquery';

const Main = () => {
    const [mobilePage, setMobilePage] = useState(false);
    const params = new URLSearchParams(window.location.search);
    const device = params.get('device');

    useEffect(() => {

        const userAgent = navigator.userAgent;
        const mobileRegex = /(iPhone|Android|Opera Mini|SymbianOS|Windows CE|BlackBerry|Nokia|SonyEricsson|webOS|PalmOS)/i;

        if(device === "pc"){
            $('body').css('min-width', '1280px');
            $('body').css('zoom', 'unset');
            setMobilePage(false);
            localStorage.setItem('isMobile', true);
        } else {
             if (mobileRegex.test(userAgent)) {
                setMobilePage(true);
            }
             // localStorage.removeItem('isMobile');
        }

    }, []);


    return (
        <>
            {mobilePage ? <MobileIndex /> : <><Header/> <Home/> <Footer/></>}
        </>
    );
};

export default Main