import React from "react";
import popup_ok from "../images/dualgo_ingame_img/ingame_popupOK.png";
import popup_warning from "../images/warning-icon.svg";
import "../css/animate.min.css";
import $ from "jquery";


function numberToKorean(number) {
    var inputNumber = number < 0 ? false : number;
    var unitWords = ['', '만', '억', '조', '경'];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString2 = '';

    for (var i = 0; i < splitCount; i++) {
        var unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0)
            resultArray[i] = unitResult;
    }

    for (var k = 0; k < resultArray.length; k++) {
        if (!resultArray[k]) resultArray[k] = "0000" //구분자넣어서 표시;
        resultString2 = String(resultArray[k]) + ';' + resultString2; //구분자넣어서 표시
    }

    return resultString2;
}


function ToKoreanNotation(money, unitString) {
    if (money === 0) {
        return 0 + unitString;
    }

    if (!money) {
        console.log('pass');
        return '';
    }

    if (10000000000000000 === money) {
        money = "9999999999999999";
    }
    else {
        money = money.toString();
    }

    var str1, str2, str3, str4, sum;

    if (money.length <= 4) {
        sum = money;
    } else if (money.length > 4 && money.length < 9) {
        //만
        str1 = money.slice(-4);
        str2 = money.slice(-money.length, -4);
        sum = str2 + '만' + str1;

    } else if (money.length > 8 && money.length < 13) {
        //억
        str1 = money.slice(-4);
        str2 = money.slice(-8, -4);
        str3 = money.slice(-money.length, -8);
        sum = str3 + '억' + str2 + '만' + str1;

    } else if (money.length > 12 && money.length < 17) {
        //조
        str1 = money.slice(-4);
        str2 = money.slice(-8, -4);
        str3 = money.slice(-12, -8);
        str4 = money.slice(-money.length, -12);
        sum = str4 + '조' + str3 + '억' + str2 + '만' + str1;

    }

    return sum + unitString;
}



// function ToKoreanNotation(amount, unitString) {
//     if (amount === 0) {
//         return 0 + unitString;
//     }
//
//     if (!amount) {
//         return '';
//     }
//
//     var amountDelimited = numberToKorean(amount).split(";");
//     var result;
//
//
//     if (amountDelimited[1] === '' || amountDelimited[2] === null)
//         amountDelimited[1] = "0000";
//
//     if (amountDelimited[2] === '' || amountDelimited[2] === null)
//         amountDelimited[2] = "0000";
//
//     var len = ('' + amount).length;
//
//     if (len >= 13) //조
//         result = amountDelimited[0] + "조" + amountDelimited[1] + "억" + amountDelimited[2] + "만" + unitString;
//     else if (len >= 9) //억
//         result = amountDelimited[0] + "억" + amountDelimited[1] + "만" + amountDelimited[2] + unitString;
//     else if (len >= 5) //만
//         result = amountDelimited[0] + "만" + amountDelimited[1] + unitString;
//     else
//         result = amountDelimited[0] + unitString;
//
//     return result;
// }


export const Popup = ({ status, popupMessage }) => {
    const closeMessagePopup = () => {
        $('.message-main-pop').css("display", "none");
    };


    return (
        <div className="message-main-pop">
            <div id="message_popup_pass" className="message_popup_container_div" style={{display: status ? 'flex' : 'none'}}>
                <div className="message-popup-container">
                    <div className="message-popup-content">
                        <img src={popup_ok} className="animated rubberBand" alt="OK"/>
                        <p id="message_popup_pass_string">{popupMessage}</p>
                    </div>
                    <div onClick={closeMessagePopup} className="message-pass-btn-web">
                        확 인
                    </div>
                </div>
            </div>

            <div id="message_popup_fail" className="message_popup_container_div" style={{display: !status ? 'flex' : 'none'}}>
                <div className="message-popup-container">
                    <div className="message-popup-content">
                        <img src={popup_warning} alt="Warning"/>
                        <p id="message_popup_fail_string">{popupMessage}</p>
                    </div>
                    <div className="message-pass-btn-web" onClick={closeMessagePopup} style={{marginTop: '30px'}}>
                        확 인
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ToKoreanNotation;

