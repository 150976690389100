import React, {useEffect, useState} from "react";
import SubHeader from "../home/subHeader";
import RightGames from "../game/rightGames";
import SubFooter from "../home/subFooter";
import ShopAvatar from "./shopAvatar";
import RightGamesWithoutLogin from "../game/rightGamesWithoutLogin";
import AddToken from "../authentication/token";

function Shop() {
    const params = new URLSearchParams(window.location.search);
    var status = params.get('message');
    var token = params.get('token');

    const [rightGame, setRightGame] = useState(false);
    const [tokenVerified, setTokenVerified] = useState(false);

    const verifyToken = async () => {
        if(localStorage.getItem('token')) {
            localStorage.setItem('gameName', 'sm_baduki');
            setRightGame(<RightGames shop={true}/>);
            setTokenVerified(true);
        } else {
            setRightGame(<RightGamesWithoutLogin/>);
            setTokenVerified(false);
        }
    };


    useEffect(() => {
        if (token) {
            AddToken(token);

            localStorage.setItem('user_verified', 'true');
            window.location.href = '/shop';
        }

        verifyToken();

        if (status) {
            alert(status);
        }
    }, [status]);

    return (
        <>
            <SubHeader bodyColor={false}/>
            <div className="TopMysection">
                <div className="TopMysection_inner" style={{justifyContent: 'left', maxWidth: '1400px'}}>
                    <div className="shop-tab">
                        <div className="shop-top-my-section-title">상점</div>

                        <div id="main_avatars" className="shop-top-my-section-tit" onClick={() => window.location.href="../shop"}>아바타</div>

                        <div id="smpl_avatars" className="shop-top-my-section-tit" onClick={() => window.location.href="../shop/?page=smpl_avatars"}>텍사스홀덤(SMPL)</div>

                    </div>
                </div>
            </div>


            <div className="ShopGameCenterwarpMain">
                <div className="ShopGameCenterwarp">
                    <div className="ShopGameCenterWarpInner" style={{marginTop: '40px'}}>
                        <div className="subMaintitleBox">
                            <div className="subMainGrayBox"></div>
                            <div className="subMaintitle"><em>아바타
                                구매</em>
                            </div>
                            <div className="subMainDepth">상점 &gt; 아바타구매</div>
                        </div>

                        <ShopAvatar tokenVerified={tokenVerified}/>

                    </div>

                    {rightGame}
                </div>
            </div>

            <SubFooter/>
        </>
    );

}

export default Shop;