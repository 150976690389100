import '../css/main.css';
import 'swiper/swiper-bundle.css';
import Slider from "./Slider";
import go_stop from "../file/Gostop.png";
import baduki from "../file/Baduki icon.png";
import poker from "../file/7 Poker icon.png";
import smpl from "../file/SMPL Icon.png";
import main_games_icon from "../file/main_game_Icon.svg";
import GameBanner from "./gameBanner";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Rectangle from "../images/main_img/Rectangle 12.png";
import axios from "axios";
import baseUrl from "../BaseUrl";

function Home() {
    const navigate = useNavigate();
    const [showRestrictionPopup, setShowRestrictionPopup] = useState(false);
    const [restrictedDate, setRestrictedDate] = useState("");

    const openGamePage = async (gameName) => {
        if (localStorage.getItem('token')) {
            const response = await axios.get(baseUrl + 'verify_user', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (!response.data.user_verified) {
                localStorage.setItem('gameName', gameName);
                return  window.location.href = '/membership_reconfirm';
            }
            else if (response.data.is_user_restricted){
                setRestrictedDate(response.data.restricted_date_upto);
                return setShowRestrictionPopup(true);
            }
            else {
                return window.location.href = `/game?gameName=${gameName}`;
            }

        } else {
            return navigate(`/login?gameName=${gameName}`);
        }
    };


    const location = useLocation();
    var restricted_date;
    try {
        restricted_date = location.state.restricted_date;
    } catch (e) {

    }


    useEffect(() => {
        const [navigationEntry] = performance.getEntriesByType('navigation');
        if (navigationEntry.type === "back_forward") {
            console.log("pass");
            window.scrollTo(0, parseInt(sessionStorage.getItem('scrollPosition'), 10));
        } else {
            sessionStorage.setItem('scrollPosition', window.scrollY);
        }

        if (restricted_date) {
            setRestrictedDate(restricted_date);
            setShowRestrictionPopup(true);
        }

        console.log(navigationEntry.type);

    }, []);


    return (
        <>
            <Slider/>

            <div className="main_game_tab_txt_div">
                <div style={{display: "block"}}>
                    <div className="main_game_tab_text">
                        <img style={{width: '30px', height: '30px',marginTop: '5px', paddingRight: '10px'}} src={main_games_icon}/>
                        주요게임
                    </div>
                    <img style={{width: '77px', height: '3px', paddingLeft: '40px'}} loading="lazy" src={Rectangle} alt="Coming Soon"/>
                </div>
            </div>

            <div style={{display: 'flex'}}>
            <div className="main_div_carosel" style={{height: 'auto', alignItems: 'start', paddingBottom: '5.3rem'}}>
                    <div className="main_div_carosel2">

                        <div id="go_stop_box" className="BoxClass GoStopBoxClass"
                             onClick={event => openGamePage("sm_dualgo")}>
                            <div className="main_div_images">
                                <img src={go_stop} alt=""/>
                            </div>
                        </div>


                        <div id="baduki_box" className="BoxClass BadukiBoxClass"
                             onClick={event => openGamePage("sm_baduki")}>
                            <div className="main_div_images">
                                <img src={baduki} alt=""/>
                            </div>
                        </div>


                        <div id="7_poker_box" className="BoxClass PokerBoxClass"
                             onClick={event => openGamePage("sm_poker")}>
                            <div className="main_div_images">
                                <img src={poker} alt=""/>
                            </div>
                        </div>

                        <div id="smpl_box" className="BoxClass SmplBoxClass" onClick={event => openGamePage("sm_smpl")}>
                            <div className="main_div_images">
                                <img src={smpl} alt=""/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <GameBanner/>


            {showRestrictionPopup && (
                <div className="user_restriction_popup"  style={{paddingTop: '300px'}}>
                    <div className="user_restriction_popup-content" style={{width: '650px', height: '270px'}}>
                        <div className="user_restrict_popup_div_1">
                            <span style={{width: '100%', textAlign: 'center'}}>자가 이용제한 안내</span>
                        </div>

                        <div className="user_restrict_popup_div_2">
                            회원님께서는 자가 이용제한을 설정하여 웹보드류 게임 이용이 제한됩니다.
                        </div>

                        <div className="user_restrict_popup_div_3">
                            <div id="restricted_date">
                                <span>해지 예정일: {restrictedDate}</span>
                            </div>
                        </div>

                        <div className="ok_no_box_button">
                            <button className="ok_no_button" onClick={event => navigate('/service_center/inquiry?page=selfUserRestriction')}>
                                이용제한내역 보기
                                <div className="man_ok_no_button_img" style={{margin: '-18px 0 0 144px'}}>
                                    <img loading="lazy" src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                                </div>
                            </button>

                            <button className="ok_no_button" style={{marginLeft: '5px'}} onClick={() => setShowRestrictionPopup(false)}>
                                확인
                                <div className="man_ok_no_button_img">
                                    <img loading="lazy" src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                                </div>
                            </button>

                        </div>

                    </div>

                </div>
            )}

        </>
    );
}

export default Home;