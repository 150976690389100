import React, {useEffect, useState} from 'react';
import '../css/membership.css';
import icon_uncheck from "../images/membership_img/Icon-uncheck.png";
import icon_check from "../images/membership_img/Icon-check.png";
import icon_down from "../images/membership_img/Icon-down.png";
import icon_mobile from "../images/membership_img/Icon-mobile.jpg";
import {useNavigate} from "react-router-dom";
import TermOfUse from "../authentication/termOfUsePolicy";
import PrivacyPolicy from "../authentication/privacyPolicy";
import MobileVerification from "../mobileVerify/mobileVerification";
import MobileHeader from "./mHeader";
import $ from "jquery";
import MobileFooter from "./mFooter";

const MobileMembership = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const error_message = params.get('status');

    const [agreeAll, setAgreeAll] = useState(false);
    const [agree1, setAgree1] = useState(false);
    const [agree2, setAgree2] = useState(false);
    const [agree3, setAgree3] = useState(false);
    const [showAgree1, setShowAgree1] = useState(false);
    const [showAgree2, setShowAgree2] = useState(false);
    const [mobileVerify, setMobileVerify] = useState(false);

    const handleAgree = (status) => {
        if (status === 'all') {
            setAgreeAll(!agreeAll);
            setAgree1(!agreeAll);
            setAgree2(!agreeAll);
            setAgree3(!agreeAll);
        } else if (status === 1) {
            setAgree1(!agree1);

            if (!agree1 && agree2 && agree3) {
                setAgreeAll(true);
            } else {
                setAgreeAll(false);
            }

        } else if (status === 2) {
            setAgree2(!agree2);

            if (agree1 && !agree2 && agree3) {
                setAgreeAll(true);
            } else {
                setAgreeAll(false);
            }

        } else if (status === 3) {
            setAgree3(!agree3);

            if (agree1 && agree2 && !agree3) {
                setAgreeAll(true);
            } else {
                setAgreeAll(false);
            }
        }

        if (agree1 && agree2 && agree3) {
            setAgreeAll(!agreeAll);
        }
    };


    const handleCheckOk = (e) => {
        if (!agree1) {
            e.preventDefault();
            alert("SM게임즈 이용약관에 동의해주세요.");
            return;
        }
        if (!agree2) {
            e.preventDefault();
            alert("SM게임즈 개인정보처리방침에 동의해주세요.");
            return;
        }
        if (!agree3) {
            e.preventDefault();
            alert("휴대폰 인증에 동의해 주세요.");
            return;
        }
        setMobileVerify(true);
    };

    const handleShowAgree = (status) => {
        if (status === 1) {
            setShowAgree1(!showAgree1);
        } else if (status === 2) {
            setShowAgree2(!showAgree2);
        }
    };

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });

        localStorage.setItem('redirectLogin', 'false');


        const [navigationEntry] = performance.getEntriesByType('navigation');

        if (navigationEntry.type === "back_forward") {
            window.history.pushState(null, "", window.location.href);
            alert('유효하지 않은 인증입니다.');
            return navigate("/");
        }

        if (error_message){
            alert('최대 5개까지 아이디를 등록할 수 있습니다.');
        }
    }, []);

    return (
        <>
            <MobileHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>회원가입</span>
                </div>

                {/* Membership Agreement */}
                <div id="contents">
                    <div className="m_center_warp">
                        <div className="m_center_warp_inner">
                            {/* Agreement */}
                            <div className="sub_main_title"><em>약관동의</em></div>

                            <div className="m_membership_box">
                                <div className="m_membership_title_Box">
                                    <div className="m_membership_title">
                                        <div className="m_membership_uncheck_img">
                                            <img id="agreeAll" src={agreeAll ? icon_check : icon_uncheck}
                                                 onClick={() => handleAgree('all')}/>
                                        </div>
                                        <em style={{paddingLeft: '4.5313vw'}}>SM게임즈 이용약관 및 개인정보 취급방침에 모두 동의합니다.</em>
                                    </div>
                                </div>

                                {/* Agreement Contents */}
                                <div className="m_membership_contents">
                                    <div className="m_membership_check_img">
                                        <img id="agree1" src={agree1 ? icon_check : icon_uncheck}
                                             onClick={() => handleAgree(1)}/>
                                    </div>
                                    <div style={{display: 'inline-block', paddingLeft: '9.3750vw'}}>
                                        <em>SM게임즈 이용약관에 동의</em>
                                    </div>
                                    <div style={{position: 'absolute', display: 'inline-block', margin: '0 0px 0px 33vw'}}
                                         onClick={() => handleShowAgree(1)}>
                                        <em>약관보기</em>
                                        <img className="membership_down_img" src={icon_down}/>
                                    </div>
                                </div>

                                {/* Show Agreement 1 */}
                                <div id="showagree1" className="agreement_Box"
                                     style={{display: showAgree1 ? 'block' : 'none'}}>
                                    <div className="m_member_agreement_contents">
                                        <div className="agreement_contents_Text">
                                            {/* Membership term of use */}

                                            <TermOfUse/>

                                        </div>
                                    </div>
                                </div>

                                {/* Agreement Contents */}
                                <div className="m_membership_contents">
                                    <div className="m_membership_check_img">
                                        <img id="agree2" src={agree2 ? icon_check : icon_uncheck}
                                             onClick={() => handleAgree(2)}/>
                                    </div>

                                    <div style={{paddingLeft: '9.375vw'}}>SM게임즈 개인정보처리방침에 동의</div>

                                    <div style={{
                                        position: 'absolute',
                                        display: 'inline-block',
                                        margin: '-3vw 0 0 62.5000vw'
                                    }} onClick={() => handleShowAgree(2)}>
                                        <em>처리방침보기</em>
                                        <img className="membership_down_img" src={icon_down}/>
                                    </div>
                                </div>

                                {/* Show Agreement 2 */}
                                <div id="showagree2" className="agreement_Box"
                                     style={{display: showAgree2 ? 'block' : 'none'}}>
                                    <div className="m_member_agreement_contents">
                                        <div className="agreement_contents_Text">
                                            {/* Membership privacy policy */}

                                            <PrivacyPolicy/>

                                        </div>
                                    </div>
                                </div>

                                {/* Agreement Checkbox */}
                                <div className="m_membership_title_Box"
                                     style={{borderBottom: 'none', borderTop: '1px solid #d3d3d3'}}>
                                    <div className="m_membership_title">
                                        <div className="m_membership_uncheck_img">
                                            <img id="agree3" src={agree3 ? icon_check : icon_uncheck}
                                                 onClick={() => handleAgree(3)}/>
                                        </div>
                                        <em style={{paddingLeft: '4.5313vw'}}>휴대폰 인증
                                            <em style={{fontSize: '2.1875vw', fontWeight: 'normal', color: '#7b7b7b'}}> 본인확인을 위한 인증절차 입니다.
                                                <img className="membership_mobile_icon" src={icon_mobile}/>
                                            </em>
                                        </em>
                                    </div>
                                </div>

                            </div>

                            <div className="m_ok_btn_box" style={{display: "flex", justifyContent: "center"}}>
                                <button className="m_ok_no_button" id="find_id_next_button" onClick={handleCheckOk}>
                                    동의
                                    <div className="m_ok_no_button_img"/>
                                </button>

                                <button className="m_ok_no_button" id="find_id_cancel_button" type="button"
                                        onClick={() => navigate('/mobile/login')}
                                        style={{backgroundColor: '#4e4e4e', marginLeft: '0.4688vw'}}>취소
                                    <div className="m_ok_no_button_img"/>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        {mobileVerify && (<MobileVerification requestData={{page: 'mobileMembershipCreate'}}/>)}

            <MobileFooter/>
        </>

    );
};

export default MobileMembership;
