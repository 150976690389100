

function AddToken(token) {
    const now = new Date();
    now.setTime(now.getTime() + 12 * 60 * 60 * 1000);
    document.cookie = `token=${token}`;
    document.cookie = `tokenTime=time; expires=${now.toUTCString()}; path=/; Secure; SameSite=Strict`;
    localStorage.setItem('token', token);
    return ;
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
}

export default AddToken;
