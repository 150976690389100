import React, {useEffect, useState} from 'react';
import $ from "jquery";
import {useNavigate} from "react-router-dom";
import footer_logo from "../images/SM Logo 1.png";

const SubFooter = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);


    function policy(category) {
        window.location.href = `/policy?page=${category}`;
    }

    useEffect(() => {
        $('.info_sectionGame .inner').css('justify-content', 'center');
        $('.info_sectionGame').css('text-align', 'center');

        const userAgent = navigator.userAgent;
        const mobileRegex = /(iPhone|Android|BlackBerry)/i;

        if (mobileRegex.test(userAgent)) {
            setIsMobile(true);
        }
    }, []);


     useEffect(() => {
        const totalHeight = document.body.scrollHeight;
        const visibleHeight = window.innerHeight;
        const scrollHeight = window.scrollY;

        if (scrollHeight +  visibleHeight>= totalHeight){
            console.log("HIiI");
        }

         $("body").css("background-color", "#333333");
    }, []);

    return (
        <div className="sub-footer">
            <div className="info_section">
                <div className="inner">
                    <div className="logo_footer">
                        <img loading="lazy" src={footer_logo} onClick={() => window.location.href = '../'}/>
                    </div>
                    <div className="info_area">
                        <address>
                            <ul className="info_lst">
                                <li className="info_item" style={{display: "flex", justifyContent: "center"}}>
                                    <div className="info_txt info_txt_hover"
                                         style={{fontSize: '14px', cursor: "pointer"}}
                                         onClick={() => navigate('/')}>회사소개
                                        <span className="info_txt_bar"></span>
                                    </div>

                                    <div className="info_txt info_txt_hover"
                                         style={{fontSize: '14px', cursor: "pointer"}}
                                         onClick={event => policy('terms_of_use')}>이용약관
                                        <span className="info_txt_bar"></span>
                                    </div>

                                    <div className="info_txt info_txt_hover"
                                         style={{fontSize: '14px', cursor: "pointer"}}
                                         onClick={event => policy('privacy_policy')}>개인정보처리방침
                                    </div>
                                </li>
                                <li className="info_item">
                                    <span className="info_txt">　</span>
                                </li>
                                <li className="info_item">
                                    <span className="info_txt">주식회사 에스엠게임즈　　</span>
                                    <span className="info_txt">대표이사 : 김수희　　</span>
                                    <span className="info_txt">통신판매업신고번호: 2023-서울영등포-0749　</span>
                                    <span className="info_txt">사업자 등록번호 : 285-88-00365　　</span>
                                    <span className="info_txt">주소: 서울시 영등포구 대림로 186, 대림하우스 703호(대림동)　　</span>
                                    <span className="info_txt">대표전화 : 1899-9365　　</span>
                                    <span className="info_txt">Email : helpdesk@smgame.co.kr　　</span>
                                </li>
                            </ul>
                        </address>

                        <p className="copyright">
                            © 2020 SMGAMES. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>

            { isMobile && (
                <div className="GOMobileButton" onClick={() => navigate('/mobile')}>모바일버전
                    <div className="GOMobileButtonImg">
                        <img loading="lazy" src={require('../images/Icon-mobilebutton.png')}/>
                    </div>
                </div>
            )}

        </div>
    );
}

export default SubFooter;
