import event_create_account from "../images/membership_img/event_createAccount.png";
import React, {useEffect, useState} from "react";
import $ from "jquery";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";

function MobileMembershipCreateOK() {
    const navigate = useNavigate();
    const [welcomeGold, setWelcomeGold] = useState('');
    const [welcomeAl, setWelcomeAL] = useState('');

    const fetchData = async () => {
        try {
            const response = await axios.get(baseUrl + "sign_up_money");
            if (response.status === 200) {
                setWelcomeGold(response.data.welcome_gold);
                setWelcomeAL(response.data.welcome_al);
            }
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
         $('body').css({
            'transform': 'unset',
            'min-width': '320px',
            'zoom': 'unset'
        });

        fetchData();
    }, []);

    return (
        <>
            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>회원가입</span>
                </div>

                    <div className="m_member_contents" style={{height: '59vh'}}>
                        <div className="m_center_warp_inner">
                            <div className="m_member_title_box">
                                가입완료
                            </div>

                            <div className="m_ok_box">
                                <div className="m_ok_title_box">
                                    <div className="m_ok_title">
                                        <em style={{color: '#15a3e1'}}>에스엠게임즈 회원가입</em><em>이 완료되었습니다.<br/>감사합니다.</em>
                                    </div>
                                </div>
                                <div className="m_ok_contents">
                                    <img src={event_create_account} className="ok_event"/>
                                    가입 축하금으로 골드머니 <em style={{color: '#c10000'}}>
                                    <a style={{color: '#c10000'}}>{welcomeGold}</a>골드</em> 와<br/>
                                    무료머니 <em style={{color: '#c10000'}}>
                                    <a style={{color: '#c10000'}}>{welcomeAl}</a>알</em> 을
                                    지급하였습니다.<br/>
                                    회원님께서는 에스엠게임즈에서 제공하는 모든 서비스를 정상적으로<br/>
                                    이용하실 수 있습니다.
                                </div>
                            </div>


                            <div className="m_ok_btn_box" style={{display: "flex", justifyContent: "center"}}>
                                <button className="m_ok_no_button" id="find_id_next_button"
                                        onClick={() => navigate('/mobile')}>
                                    확인
                                    <div className="m_ok_no_button_img"/>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>

        </>
    );
}

export default MobileMembershipCreateOK;