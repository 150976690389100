import React, {useEffect, useState} from 'react';
import '../css/webPopup.css';
import $ from "jquery";
import popup_heading_1 from "../images/popup/popup-freecharge.svg";
import close_icon from "../images/popup/closeIcon.svg";
import gold_icon_white from "../images/currency-icon-white.svg";
import egg_icon_color from "../images/egg-icon-color.svg";
import gold_icon_color from "../images/currency-color-icon.svg";
import egg_icon_white from "../images/egg-white-icon.svg";
import star_icon from "../images/star-ico.svg";
import star_icon_without from "../images/star-icon-without-color.svg";
import axios from "axios";
import baseUrl from "../BaseUrl";
import ToKoreanNotation, {Popup} from "./popupComman";



const FreeChargePopup = ({gameName, rigthGamedata}) => {
    const [currency, setCurrency] = useState('go');
    const [unitDisplayGO, setUnitDisplayGO] = useState('');
    const [goldIcon, setGoldIcon] = useState(gold_icon_white);
    const [eggIcon, setEggIcon] = useState(egg_icon_color);
    const [currencyUnit, setCurrencyUnit] = useState('');
    const [moneyData, setMoneyData] = useState({go: {}, al: {}});
    const [messagePopupStatus, setMessagePopupStatus] = useState(false);
    const [popupMessage, setPopupMessage] = useState(false);

    const closeCashPopup = () => {
        $('html').css('overflow', 'scroll');
        $(".freecharge_popup").css('display', 'none');

        rigthGamedata();
    };

    const changeCurrency = (currency_type) => {
        setCurrency(currency_type);

        if (currency_type === 'go') {
            setGoldIcon(gold_icon_white);
            setEggIcon(egg_icon_color);
            setCurrencyUnit(unitDisplayGO);
        } else {
            setGoldIcon(gold_icon_color);
            setEggIcon(egg_icon_white);
            setCurrencyUnit('알');
        }

    };



    const fetchMoneyData = async () => {
        try {
            const response = await axios.get(baseUrl + 'popups/free_charge/', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (response.status === 200){
                setMoneyData(response.data);
            }

        } catch (e) {

        }
    };

    async function handleOnSubmit() {
        if (!moneyData[currency].free_charge_possible) {
            setMessagePopupStatus(false);
            setPopupMessage(moneyData[currency].free_charge_title);
            $('.message-main-pop').css("display", "block");
            $('#message_popup_fail_string').css({
                "width": "255px",
                "text-align": "center"
            });
            return ;
        }

        const data = {
            currency: currency,
        };


        try {
            const response = await axios.post(baseUrl + 'popups/update_free_charge/', data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                sessionStorage.clear();
                setMessagePopupStatus(true);
                setPopupMessage("무료충전이 완료되었습니다.");
                $('.message-main-pop').css("display", "block");
                setMoneyData(response.data);
            } else if(response.status === 204) {
                setMessagePopupStatus(false);
                setPopupMessage("게임 진행 중에는 금고 입•출금이 제한 됩니다.");
                $('.message-main-pop').css("display", "block");
            }

        } catch (e) {
            console.log(e);
            setMessagePopupStatus(false);
            setPopupMessage("금액 초과 무료충전이 제한됩니다.");
            $('.message-main-pop').css("display", "block");
        }


    }


    useEffect(() => {
        fetchMoneyData();

        $('.freecharge_popup').css("display", "block");


        if (gameName === "sm_dualgo") {
            setUnitDisplayGO("냥");
            setCurrencyUnit("냥");
        }
        else {
            setUnitDisplayGO("골드");
            setCurrencyUnit("골드");
        }
    }, [gameName]);


    return (
        <div id="include_page" className="freecharge_popup">
            <div className="popup-container">
                <div className="popup-heading">
                    <img loading="lazy" src={popup_heading_1} alt=""/>
                    <div className="close-icon" onClick={closeCashPopup}>
                        <img loading="lazy" src={close_icon} alt=""/>
                    </div>
                </div>

                <div className="inner-container">
                    <div className="currency-switch">
                        <div
                            onClick={() => changeCurrency('go')}
                            className="currency-option"
                            style={{borderRight: '0', backgroundColor: currency === 'go' ? '#004279' : 'white'}}
                        >
                            <img loading="lazy" src={goldIcon} alt="currency-symbol-1" style={{width: '20px', height: '20px'}}/>
                            <p style={{
                                color: currency === 'go' ? '#fff' : '#004985',
                                fontSize: '20px'
                            }}>{unitDisplayGO}</p>
                        </div>
                        <div
                            onClick={() => changeCurrency('al')}
                            className="currency-option"
                            style={{backgroundColor: currency === 'al' ? '#004279' : 'white'}}
                        >
                            <img loading="lazy" src={eggIcon} alt="currency-symbol-2" style={{width: '20px', height: '20px'}}/>
                            <p style={{color: currency === 'al' ? '#fff' : '#004985', fontSize: '20px'}}>알</p>
                        </div>
                    </div>

                    <div className="contents-inner-box">
                        <div className="tab-content">
                                <div className="current-tab-div"  style={{marginTop: "50px"}}>
                                    <div style={{display: 'flex', gap: '8px'}}>
                                        <div className="label-box">
                                            보유머니
                                        </div>
                                        <div className="money-box">
                                            <div>{ToKoreanNotation(moneyData[currency].total_money, currencyUnit)}</div>
                                        </div>
                                    </div>
                                </div>


                                <div className="free_charge-container">
                                    <img loading="lazy" src={star_icon} alt="" />
                                    <p>
                                        {moneyData[currency].free_charge_title}
                                    </p>
                                </div>


                                <div className="web-popup-btn-container"  style={{marginTop: "50px"}}>
                                    <div
                                        className="web-popup-btn"
                                        onClick={() => handleOnSubmit()}
                                    >
                                        <div className="web-popup-btn-title">
                                            확인
                                        </div>
                                    </div>
                                </div>

                                <div className="free_charge_limit">
                                    <img loading="lazy" src={star_icon_without} alt="" />
                                    <p>총 보유머니가 {moneyData[currency].free_charge_amount}{currencyUnit} 미만일 때 무료충전이 가능합니다.</p>
                                </div>

                            </div>
                    </div>

                </div>
                <Popup status={messagePopupStatus} popupMessage={popupMessage}/>)

            </div>
        </div>

    );
};

export default FreeChargePopup;
