import React, {useEffect, useState} from "react";
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import $ from 'jquery';
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useLocation} from "react-router-dom";

const MobileQAGame = () => {
    const location = useLocation();
    var gameName = "sm_dualgo";

    try {
        gameName = location.state.gameName;
    } catch (e) {

    }

    const [tabs, setTabs] = useState([false, false, false, false, false]);
    const [qaItems, setQaItems] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [faqGameNum, setFaqGameNum] = useState(1);
    const [qaTitle, setQATitle] = useState('');
    const [searchTxt, setSearchTxt] = useState(null);

    const qaData = async (offset, faqGameNum) => {
        console.log(searchTxt);
        var subUrl;
        if (searchTxt) {
            subUrl = 'service_center/game_qa/?faq_game='+faqGameNum+'&search='+searchTxt+'&ordering=-faq_no&limit=5&offset='+offset;
        } else {
            subUrl = 'service_center/game_qa/?faq_game=' + faqGameNum + '&ordering=-faq_no&limit=5&offset=' + offset;
        }

        try {
            const response = await axios.get(baseUrl + subUrl);
            if (response.status === 200) {
                setQaItems(response.data.results);
                setNumberOfPages(Math.ceil(response.data.count / 5));
            }
        } catch (e) {

        }
    };

    function changeQAPage(index) {
        var offset = index * 5;
        setPageIndex(index);
        qaData(offset, faqGameNum);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchTxt();
        }
    };

    const openTab = (event, tab_index) => {
        const newTabs = [false, false, false, false, false];
        newTabs[tab_index] = !tabs[tab_index];
        setTabs(newTabs);
    };

    function handleInputChange(event) {
        setSearchTxt(event.target.value);
    }

    function handleSearchTxt() {
        qaData(0, faqGameNum);
    }

    function handleQAGame() {
        qaData(0, faqGameNum);
    }

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });

        $('.m_footer_box').css('position', 'relative');

        var faqGameNo;
        if(gameName === 'sm_dualgo') {
            faqGameNo = 1;
            setQATitle('신맞고 도움말');
        }

        else if (gameName === 'sm_baduki'){
            faqGameNo = 3;
            setQATitle('바둑이 도움말');
        }

        else if (gameName === 'sm_poker'){
            faqGameNo = 2;
            setQATitle('SM 7포커 도움말');
        }

        else if (gameName === 'sm_smpl'){
            faqGameNo = 5;
            setQATitle('SMPL 도움말');
        }

        else if (gameName === 'other'){
            faqGameNo = 4;
            setQATitle('홈페이지 도움말');
        }

        else {
            faqGameNo = 1;
            setQATitle('신맞고 도움말');
        }



        setFaqGameNum(faqGameNo);
        qaData(0, faqGameNo);
    }, [gameName]);


    return (
        <>
            <MobileHeader/>

            <div style={{background: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>고객센터</span>
                </div>

                <div className="m_qa_content">
                    <div className="m_qa_title_box" onClick={handleQAGame}>
                        {qaTitle}
                    </div>

                    <div className="m_notice_search_box">
                        <div className="m_notice_input_box">
                            <input
                                type="text"
                                id="SerchText"
                                name="Serch"
                                placeholder="검색어를 입력하세요"
                                autoComplete="off"
                                value={searchTxt}
                                onChange={event => handleInputChange(event)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                        <div className="m_notice_search_box_btn" onClick={handleSearchTxt}>검색</div>
                    </div>

                    <div className="m_qa_box_border">

                        {qaItems.map((item, index) => (
                            <>
                                <div className="m_qa_box" onClick={event => openTab(event, index)}>
                                    <div className="m_aq_title_box_2">
                                        <div className="m_qa_inner">
                                            <button className="m_quit_ok_no_button_2">Q</button>
                                            {item.faq_title}
                                        </div>

                                        <div className="m_qa_button_img"/>
                                    </div>
                                </div>

                                <div className="m_qa_box" style={{display: tabs[index] ? 'block' : 'none'}}>
                                    <div className="m_aq_title_box_3">
                                        <div className="m_qa_inner_2">
                                            <div>
                                                <button className="m_quit_ok_no_button_2"
                                                        style={{backgroundColor: '#4e4e4e'}}>A
                                                </button>
                                            </div>
                                            <div className="m_qa_inner_3"
                                                 dangerouslySetInnerHTML={{__html: item.faq_contents}}>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </>
                        ))}

                        <div className="m_qa_pagination_box">
                            <div className="m_qa_pagination_box_2">
                                {Array.from({length: numberOfPages}, (_, index) => (
                                    <div key={index} onClick={event => changeQAPage(index)}>
                                        <div
                                            className={`m_qa_pagination_buttons ${index === pageIndex ? 'm_qa_pagination_active' : ''}`}>
                                            {index + 1}
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <MobileFooter/>
        </>
    );
}

export default MobileQAGame;